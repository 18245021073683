import React from "react";
import {Tabs} from "@pankod/refine-antd";
import {DexWalletsWorkedList} from "./dex-wallets-worked-list";
import {DexWalletsHoldersList} from "./dex-wallets-holders-list";

export const DexWalletsList: React.FC<{ showId: string, pair: String | undefined }> = (params) => {
    return <Tabs
        type="card"
        items={[
            {
                label: `Working wallets`,
                key: '11',
                children: <DexWalletsWorkedList showId={String(params.showId)}
                                                pair={params?.pair}></DexWalletsWorkedList>,
            },
            {
                label: `Holders`,
                key: '12',
                children: <DexWalletsHoldersList showId={String(params.showId)}></DexWalletsHoldersList>,
            }
        ]}
    />;
};
