import {IResourceComponentsProps, useList} from "@pankod/refine-core";
import {Button, Checkbox, Create, Divider, Form, Input, Typography, useForm} from "@pankod/refine-antd";

import {CreateBotRightsDto, IBot, IUserCreate} from "interfaces";
import React, {useEffect} from "react";
import {PasswordGenerator} from "../../utils/password-generator";
import {BotPermissionsForm} from "./bot-permissions-form";

const {Title} = Typography;

function getBotPermissions(data: IBot[] | undefined): CreateBotRightsDto [] {
    if (!data) {
        return new Array<CreateBotRightsDto & { botName: string }>();
    }

    return data.map(value => {
        return {
            botId: value.id,
            botName: value.botName,
            read: false,
            write: false,
            administration: false,
            full: false,
        }
    })

}

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps} = useForm<IUserCreate>({
        resource: 'users'
    });

    const botsData = useList<IBot>(
        {
            resource: 'bots',
        }
    );

    const botPermissions = [
        {label: 'Creating bots', value: 'botCreate'},
    ];

    const userPermissions = [
        {label: 'Viewing users and their data', value: 'userView'},
        {label: 'Manage users (create, edit, delete)', value: 'userManage'},
    ];


    // @ts-ignore
    const handleClick = event => {
        const passwordGen = PasswordGenerator.generate({
            length: 16,
            useDigits: true,
            useLower: true,
            useUpper: true,
            usePunctuation: true
        });

        formProps.form?.setFieldValue("password", passwordGen)
    };

    let onChangeBotRights = (checkedValues: any) => {
        let manageRights = formProps.form?.getFieldValue("manageRights") ?? {};

        botPermissions.forEach(value => {
            let key = value.value;

            manageRights[key] = Array.of(...checkedValues).includes(key)
        })

        formProps.form?.setFieldValue("manageRights", manageRights)
    };

    let onChangeUserRights = (checkedValues: any) => {
        let manageRights = formProps.form?.getFieldValue("manageRights") ?? {};

        userPermissions.forEach(value => {
            let key = value.value;

            manageRights[key] = Array.of(...checkedValues).includes(key)
        })

        formProps.form?.setFieldValue("manageRights", manageRights)
    };

    useEffect(() => {
        formProps.form?.setFieldValue("botsRights", getBotPermissions(botsData.data?.data))
    }, [botsData.isLoading])


    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item label="Email"
                           name="email"
                           rules={[{type: "email", required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="First name"
                           name="firstName"
                           rules={[{required: true}]}>
                    <Input/>

                </Form.Item>
                <Form.Item label="Last name"
                           name="lastName"
                           rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Telegram username"
                           name="telegramUsername"
                           rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Password"
                           name="password"
                           rules={[{required: true,}]}>
                    <Input/>
                </Form.Item>

                <Button onClick={handleClick}>Generate password</Button>

                <Divider>Management permissions</Divider>

                <Form.Item name="manageRights">
                    <Title level={5}>Bot permissions</Title>
                    <Checkbox.Group options={botPermissions} onChange={onChangeBotRights}/>

                    <Title level={5}>User permissions</Title>
                    <Checkbox.Group options={userPermissions} onChange={onChangeUserRights}/>
                </Form.Item>

                <Divider>Bot permissions</Divider>
                <BotPermissionsForm {...formProps}></BotPermissionsForm>
            </Form>
        </Create>
    );
};
