import {IWallet} from "interfaces";
import React, {useEffect, useState} from "react";
import {DateField, Divider, NumberField, Table, Tag, TextField, useTable} from "@pankod/refine-antd";

export const DexWalletsHoldersList: React.FC<{ showId: string }> = (params) => {

    const {tableProps} = useTable<IWallet>({
        resource: 'wallets',
        initialFilter: [
            {
                field: 'botId',
                value: params.showId,
                operator: "eq"
            },
            {
                field: 'isWorking',
                value: false,
                operator: "eq"
            }
        ],
        hasPagination: false
    });

    let [balances, setBalances] = useState([0, 0, 0]);

    useEffect(() => {
        if (tableProps?.dataSource) {
            let balances = [0, 0, 0]
            tableProps.dataSource.forEach(wallet => {
                balances[0] = Number(balances[0] + Number(wallet.coreBalance))
                balances[1] = Number(balances[1] + Number(wallet.coin1Balance))
                balances[2] = Number(balances[2] + Number(wallet.coin2Balance))
            })

            setBalances(balances)
        }
    }, [tableProps?.dataSource])

    return (<div>
            <Divider>Holders wallets</Divider>
            <div><TextField value={'Holders core balance = ' + balances[0]}></TextField></div>
            <div><TextField value={'Holders coin1 balance = ' + balances[1]}></TextField></div>
            <div><TextField value={'Holders coin2 balance = ' + balances[2]}></TextField></div>
            <Table {...tableProps} rowKey="id">
                <Table.Column
                    dataIndex="id"
                    key="id"
                    title="ID"
                    render={(value) => <TextField value={value}/>}
                />
                <Table.Column
                    dataIndex="walletAddress"
                    key="walletAddress"
                    title="Address"
                    render={(value) => <TextField value={value}/>}
                />
                <Table.Column
                    dataIndex="type"
                    key="type"
                    title="Type"
                    render={(value) => <Tag
                        color={value === 'main' ? "rgba(29,122,0,0.75)" : value === 'proxy' ? "#797373" : "#0d4c94"}>{value?.toUpperCase() ?? ''}</Tag>}
                />
                <Table.Column
                    dataIndex="coreBalance"
                    key="coreBalance"
                    title="Core balance"
                    render={(value) => <NumberField value={value} options={{maximumFractionDigits: 8}}/>}
                />
                <Table.Column
                    dataIndex="coin1Balance"
                    key="coin1Balance"
                    title="Coin1 balance"
                    defaultSortOrder={"descend"}
                    render={(value) => <NumberField value={value} options={{maximumFractionDigits: 8}}/>}
                />
                <Table.Column
                    dataIndex="coin2Balance"
                    key="coin2Balance"
                    title="Coin2 balance"
                    render={(value) => <NumberField value={value} options={{maximumFractionDigits: 8}}/>}
                />
                <Table.Column
                    dataIndex="transactionCount"
                    key="transactionCount"
                    title="Transactions count"
                    render={(value) => <NumberField value={value ?? 0}/>}
                />
                <Table.Column
                    dataIndex="status"
                    key="status"
                    title="Status"
                    render={(status) => <Tag
                        color={
                            status === 0 ? "rgba(224,238,221,0.75)" :
                                status === 1 ? "#b08d48" :
                                    status === 2 ? "#5fb94b" :
                                        status === 3 ? "#5d0959" :
                                            status === 4 ? "#961818" :
                                                status === 5 ? "#ce7474" :
                                                    "#101e19"}>{
                        status === 0 ? "NEW" :
                            status === 1 ? "REPLENISHING" :
                                status === 2 ? "WORKING" :
                                    status === 3 ? "SENDING_TOKENS" :
                                        status === 4 ? "USED" :
                                            status === 5 ? "WITHDRAWAL_BALANCE" :
                                                "INACTIVE"
                    }</Tag>}
                />
                <Table.Column
                    dataIndex="createdAt"
                    key="createdAt"
                    title="Created date"
                    render={(value) => <DateField format="L LT" value={value}/>}
                />
                <Table.Column
                    dataIndex="updatedAt"
                    key="updatedAt"
                    title="Updated date"
                    render={(value) => <DateField format="L LT" value={value}/>}
                />
            </Table>
        </div>
    );
};
