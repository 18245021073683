import {Checkbox, Form, FormProps, Space} from "@pankod/refine-antd";
import React from "react";

export const BotPermissionsForm: React.FC<FormProps> = (formProps) => {

    // @ts-ignore
    const handleCheckOn = event => {
        const strings = String(event.target.id).split("_");
        const id = strings[1];
        const eventField = strings[2];
        let checked = event.target.checked;
        let botRights = formProps.form?.getFieldValue("botsRights");

        if (eventField !== "full" && !checked) {
            botRights[id]["full"] = checked;
        }


        if (eventField === "full") {
            botRights[id]["full"] = checked;
            botRights[id]["read"] = checked;
            botRights[id]["write"] = checked;
            botRights[id]["administration"] = checked;
        }

        if (eventField === "read") {
            botRights[id]["read"] = checked;

            if (!checked) {
                botRights[id]["write"] = checked;
            }
        }

        if (eventField === "write") {
            botRights[id]["write"] = checked;

            if (checked) {
                botRights[id]["read"] = checked;
            }
        }

        if (eventField === "administration") {
            botRights[id]["administration"] = checked;
        }


        if (eventField !== "full" && botRights[id]["read"] && botRights[id]["write"] && botRights[id]["administration"]) {
            botRights[id]["full"] = true;
        }


        formProps.form?.setFieldValue("botsRights", botRights);
    }

    return (
        <Form.List name="botsRights" initialValue={formProps.form?.getFieldValue("botsRights")}>
            {(fields) => (
                <div>
                    {fields.map(({key, name, ...restField}) => (
                        <Form.Item key={key} label={formProps.form?.getFieldValue("botsRights")[name].botName}>
                            <Space {...restField} style={{display: "flex", marginBottom: 8}} align="baseline">
                                <Form.Item {...restField} name={[name, "read"]}>
                                    <Checkbox onChange={handleCheckOn}
                                              checked={formProps.form?.getFieldValue("botsRights")[name].read}>Read
                                        only</Checkbox>
                                </Form.Item>
                                <Form.Item {...restField} name={[name, "write"]}>
                                    <Checkbox onChange={handleCheckOn}
                                              checked={formProps.form?.getFieldValue("botsRights")[name].write}>Write</Checkbox>
                                </Form.Item>
                                <Form.Item {...restField} name={[name, "administration"]}>
                                    <Checkbox onChange={handleCheckOn}
                                              checked={formProps.form?.getFieldValue("botsRights")[name].administration}>Administration</Checkbox>
                                </Form.Item>
                                <Form.Item {...restField} name={[name, "full"]}>
                                    <Checkbox onChange={handleCheckOn}
                                              checked={formProps.form?.getFieldValue("botsRights")[name].full}>Full
                                        access</Checkbox>
                                </Form.Item>
                            </Space>
                        </Form.Item>
                    ))}
                </div>
            )}
        </Form.List>
    );
};
