import {Checkbox, Form, FormProps, Input, Select} from "@pankod/refine-antd";
import React from "react";

export const BotCEXCreate: React.FC<{ formProps: FormProps, exchanges: string[] | undefined, isCEX: boolean }> = (props) => {
    return (
        <Form {...props.formProps} layout="vertical">
            <Form.Item label="Is CEX"
                       name="isCEX"
                       hidden={true}
                       initialValue={props.isCEX}>
                <Checkbox checked={props.isCEX}/>
            </Form.Item>
            <Form.Item label="Exchange"
                       name="exchange"
                       rules={[{required: true}]}>
                <Select showSearch
                        placeholder="Select an exchange"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={props.exchanges?.map(value => {
                            return {
                                value: value,
                                label: value
                            }
                        })}
                />
            </Form.Item>
            <Form.Item label="Pair"
                       name="pair"
                       rules={[{required: true, max: 255}]}>
                <Input placeholder={"COIN1/COIN2"}/>
            </Form.Item>
            <Form.Item label="Bot name"
                       name="botName"
                       rules={[{required: true, max: 255},]}>
                <Input placeholder={"R2D2"}/>
            </Form.Item>
            <Form.Item label="Welcome string"
                       name="welcomeString"
                       initialValue={"Hi. I'm a bot!"}
                       rules={[{required: true, max: 1000}]}>
                <Input/>
            </Form.Item>
            <Form.Item label="Telegram API token"
                       name="telegramApiToken"
                       rules={[{required: true}]}>
                <Input placeholder={"You should generate it in telegram bot"}/>
            </Form.Item>
            <Form.Item label="API key"
                       name="apiKey"
                       rules={[{required: true}]}>
                <Input placeholder={"Enter an exchange API key"}/>
            </Form.Item>
            <Form.Item label="API secret"
                       name="apiSecret"
                       rules={[{required: true}]}>
                <Input placeholder={"Enter an exchange API secret"}/>
            </Form.Item>
        </Form>
    );
};
