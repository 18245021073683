import {IResourceComponentsProps, useList} from "@pankod/refine-core";
import {Checkbox, Divider, Edit, Form, Input, Space, Typography, useForm,} from "@pankod/refine-antd";

import {CreateBotRightsDto, IBot, IUser} from "interfaces";
import React, {useEffect, useState} from "react";
import {BotPermissionsForm} from "./bot-permissions-form";

const {Title} = Typography;

function getBotPermissions(data: IBot[] | undefined, initialValues: IUser | undefined): CreateBotRightsDto [] {

    if (!data) {
        return new Array<CreateBotRightsDto & { botName: string }>();
    }
    return data.map(bot => {

        let botId = bot.id;
        let userRight = initialValues?.userRight;

        const write: boolean = userRight?.botPermissions.write.includes(botId) ?? false;
        const read: boolean = write ? write : userRight?.botPermissions.read.includes(botId) ?? false;
        const administration: boolean = userRight?.botPermissions.administration.includes(botId) ?? false;

        return {
            botId,
            botName: bot.botName,
            read,
            write,
            administration,
            full: write && read && administration
        }
    })
}


export const UserEdit: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps, queryResult} = useForm<IUser>(
        {resource: 'users'}
    );
    const botsData = useList<IBot>(
        {
            resource: 'bots',
        }
    );

    let [botValues, setBotValues] = useState(new Array<string>());
    let [userValues, setUserValues] = useState(new Array<string>());

    const botOptions = [
        {label: 'Creating bots', value: 'botCreate'},
    ]

    const userOptions = [
        {label: 'Viewing users and their data', value: 'userView'},
        {label: 'Manage users (create, edit, delete)', value: 'userManage'},
    ]

    let onChange = (event: any) => {
        const strings = String(event.target.id).split("_");
        const id = strings[0];
        const eventField = strings[1];

        let checked = event.target.checked;
        let fieldValue = formProps.form?.getFieldValue(id);

        fieldValue[eventField] = checked;

        formProps.form?.setFieldValue(id, fieldValue)
    };

    useEffect(() => {
        formProps.form?.setFieldValue("botsRights", getBotPermissions(botsData.data?.data, (formProps.initialValues as IUser | undefined)));

        let data = queryResult?.data?.data;

        formProps.form?.setFieldValue("manageRights", {
            botCreate: data?.userRight?.managementPermissions?.bots?.create ?? false,
            userView: data?.userRight?.managementPermissions?.users?.view ?? false,
            userManage: data?.userRight?.managementPermissions?.users?.manage ?? false
        });

        setBotValues(botOptions
            .filter(option => !!formProps.form?.getFieldValue("manageRights")[option.value])
            .map(option => option.value))

        setUserValues(userOptions
            .filter(option => !!formProps.form?.getFieldValue("manageRights")[option.value])
            .map(option => option.value))

    }, [botsData.isLoading, queryResult?.isLoading])

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item label="First name"
                           name="firstName"
                           rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Last name"
                           name="lastName"
                           rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Telegram username"
                           name="telegramUsername"
                           rules={[{required: true}]}>
                    <Input/>
                </Form.Item>

                <Divider>Management permissions</Divider>

                <Title level={5}>Bot management permissions</Title>
                <Space>
                    <Form.Item key={0} name={["manageRights", "botCreate"]}>
                        <Checkbox defaultChecked={formProps.form?.getFieldValue("manageRights")?.botCreate}
                                  onChange={onChange}>Creating bots</Checkbox>
                    </Form.Item>
                </Space>

                <Title level={5}>User management permissions</Title>
                <Space>
                    <Form.Item key={1} name={["manageRights", "userView"]}>
                        <Checkbox defaultChecked={formProps.form?.getFieldValue("manageRights")?.userView}
                                  onChange={onChange}>Viewing users and their data</Checkbox>
                    </Form.Item>
                    <Form.Item key={2} name={["manageRights", "userManage"]}>
                        <Checkbox defaultChecked={formProps.form?.getFieldValue("manageRights")?.userManage}
                                  onChange={onChange}>Manage users (create, edit, delete)</Checkbox>
                    </Form.Item>
                </Space>

                <Divider>Bot permissions</Divider>
                <BotPermissionsForm {...formProps}></BotPermissionsForm>
            </Form>
        </Edit>
    );
};
