import React, {FC, useState} from "react";

import {Refine} from "@pankod/refine-core";
import {
    Button,
    ConfigProvider,
    ErrorComponent,
    Layout,
    notificationProvider,
    ReadyPage,
    Space,
    theme
} from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider, {axiosInstance} from "@pankod/refine-simple-rest";
import {UserCreate, UserEdit, UserList, UserShow} from "./pages/users";
import {authProvider} from "./authProvider";
import "@pankod/refine-antd/dist/reset.css";
import {Footer, OffLayoutArea, Sider, Title} from "./components/layout";
import {BotList} from "./pages/bots/list";
import {BotShow} from "./pages/bots/show";
import {BotCreate} from "./pages/bots/create";
import {BotEdit} from "./pages/bots/edit";
import {Login} from "./pages/login";
import {convertLocalDateToUTCIgnoringTimezone} from "./utils";

axiosInstance.interceptors.request.use(
    // Here we can perform any function we'd like on the request
    (request) => {
        // Retrieve the token from local storage
        // @ts-ignore
        const token = JSON.parse(localStorage.getItem("auth"));
        // Check if the header property exists
        if (request.headers) {
            // Set the Authorization header if it exists
            request.headers["Authorization"] = `Bearer ${token}`;
        } else {
            // Create the headers property if it does not exist
            request.headers = {
                Authorization: `Bearer ${token}`,
            };
        }

        return request;
    },
);

interface HeaderProps {
    theme: "light" | "dark";
    setTheme: (theme: "light" | "dark") => void;
}

const Header: FC<HeaderProps> = (props) => {
    return (
        <Space
            direction="vertical"
            align="end"
            style={{
                padding: "1rem",
            }}
        >
            <Button
                onClick={() => {
                    props.setTheme(props.theme === "light" ? "dark" : "light");
                }}>
                {props.theme === "light" ? 'Dark mode' : 'Light mode'}
            </Button>
        </Space>
    );
};

async function getUser() {
    let baseRecordCustomResponse = await dataProvider(String(process.env.REACT_APP_MMGRAPH_BACKEND)).custom({
        url: `${String(process.env.REACT_APP_MMGRAPH_BACKEND)}/users/info`,
        method: "get",
    });
    const user = baseRecordCustomResponse.data;

    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('refreshAt', JSON.stringify(convertLocalDateToUTCIgnoringTimezone(new Date()).getTime()));

    return user;
}

function App() {
    const [currentTheme, setCurrentTheme] = useState<"light" | "dark">("light");
    return (
        <ConfigProvider
            theme={{
                algorithm:
                    currentTheme === "light"
                        ? theme.defaultAlgorithm
                        : theme.darkAlgorithm,
                token: {
                    colorPrimary: "#5725b6"
                },
                components: {
                    Menu: {
                        colorItemText: currentTheme === "light" ? "#000000" : "#ffffff"
                    }
                }
            }}
        >
            <Refine
                dataProvider={dataProvider(String(process.env.REACT_APP_MMGRAPH_BACKEND))}
                notificationProvider={notificationProvider}
                ReadyPage={ReadyPage}
                authProvider={authProvider}
                LoginPage={Login}
                catchAll={<ErrorComponent/>}
                Title={Title}
                Header={() => (<Header theme={currentTheme} setTheme={setCurrentTheme}/>)}
                Sider={Sider}
                Footer={Footer}
                Layout={Layout}
                OffLayoutArea={OffLayoutArea}
                routerProvider={routerProvider}
                accessControlProvider={{
                    can: async ({resource, action, params}) => {
                        // @ts-ignore
                        let user = JSON.parse(localStorage.getItem('user'));
                        // @ts-ignore
                        let refreshDataAt = JSON.parse(localStorage.getItem('refreshAt'));

                        if (!refreshDataAt || convertLocalDateToUTCIgnoringTimezone(new Date()).getTime() - refreshDataAt > 60000) {
                            user = await getUser();
                        }

                        if (resource === "users") {
                            if (action === "list" || action === "show") {
                                action = 'view'
                            } else {
                                action = 'manage'
                            }
                        }

                        let botId = params?.id;
                        if (resource === "bots" && !!botId) {
                            if (action === "show") {
                                return Promise.resolve({can: user.userRight.botPermissions.read.includes(botId)});
                            }

                            if (action === "edit" || "delete") {
                                return Promise.resolve({can: user.userRight.botPermissions.write.includes(botId)});
                            }


                        }

                        try {
                            const can = user.userRight.managementPermissions[resource][action] ?? true;
                            return Promise.resolve({can});
                        } catch (e) {
                            return Promise.resolve({can: true});
                        }

                    },
                }}
                resources={[
                    {
                        name: "bots",
                        list: BotList,
                        show: BotShow,
                        create: BotCreate,
                        edit: BotEdit,
                    },
                    {
                        name: "users",
                        list: UserList,
                        create: UserCreate,
                        edit: UserEdit,
                        show: UserShow,
                    },
                ]}
            />
        </ConfigProvider>
    );
}

export default App;
