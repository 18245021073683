import {IResourceComponentsProps, useShow} from "@pankod/refine-core";
import {Divider, Show, Typography} from "@pankod/refine-antd";

import {IBot} from "interfaces";
import React from "react";

import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip,} from 'chart.js';
import {DexVolumeChart} from "./show-components/dex-volume-chart";
import {BotOrdersList} from "./show-components/cex-orders.list";
import {DexWalletsList} from "./show-components/dex-wallets-list";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend
);


const {Title, Text} = Typography;

export const BotShow: React.FC<IResourceComponentsProps> = () => {
    const {showId, queryResult} = useShow<IBot>(
        {
            resource: 'bots'
        }
    );

    const {data, isLoading} = queryResult;
    const record = data?.data;

    let generalBotData = (
        <div>
            <Divider>Bots data</Divider>

            <Title level={5}>ID</Title>
            <Text>{record?.id}</Text>

            <Title level={5}>Name</Title>
            <Text>{record?.botName}</Text>

            <Title level={5}>Pair</Title>
            <Text>{record?.pair}</Text>

            <Title level={5}>Exchange</Title>
            <Text>{record?.exchange}</Text>

            <Title level={5}>Telegram API token</Title>
            <Text>{record?.telegramApiToken}</Text>
        </div>
    );

    return (
        <Show isLoading={isLoading}>
            {record?.isCEX ? (
                <>
                    {generalBotData}
                    <Divider>Opened orders</Divider>
                    <BotOrdersList key={1} botId={String(showId)}></BotOrdersList>
                </>
            ) : (
                <>
                    {generalBotData}
                    <Divider>Month volumes</Divider>
                    <DexVolumeChart key={2} showId={String(showId)}></DexVolumeChart>

                    <Divider>Wallets</Divider>
                    <DexWalletsList key={3} showId={String(showId)} pair={record?.pair}></DexWalletsList>
                </>
            )}
        </Show>
    );
};
