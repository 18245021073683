export interface Options {
    length: number;
    useLower: boolean;
    useUpper: boolean;
    useDigits: boolean;
    usePunctuation: boolean;
}

const LOWER = 'abcdefghijklmnopqrstuvwxyz';
const UPPER = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const DIGITS = '0123456789';
const PUNCTUATION = '#?!@$%^&*-';

export class PasswordGenerator {
    public static generate(options: Options) {
        const {length, usePunctuation, useUpper, useLower, useDigits} = options;

        // Argument Validation.
        if (length <= 0) {
            return '';
        }

        let password = '';

        const charCategories = new Array<String>();
        if (useLower) {
            charCategories.push(LOWER);
        }
        if (useUpper) {
            charCategories.push(UPPER);
        }
        if (useDigits) {
            charCategories.push(DIGITS);
        }
        if (usePunctuation) {
            charCategories.push(PUNCTUATION);
        }

        const size = charCategories.length;

        for (let i = 0; i < length; i++) {
            let categoryID = i < size ? i : this.getRandomInt(size);

            const charCategory: String = charCategories[categoryID];
            const position = this.getRandomInt(charCategory.length);

            password = password + (charCategory.charAt(position));
        }

        return String(password);
    }

    private static getRandomInt(max: number): number {
        return Math.floor(Math.random() * max);
    }

}
