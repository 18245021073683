import React, {useMemo, useState} from "react";
import {
    Button,
    DateField,
    DeleteButton,
    EditButton,
    notification,
    ShowButton,
    Space,
    Table,
    TableProps,
    Tag,
    TextField
} from "@pankod/refine-antd";
import {SyncOutlined,} from "@ant-design/icons";
import type {NotificationPlacement} from 'antd/es/notification/interface';


import {IBot} from "../../../interfaces";
import dataProvider from "@pankod/refine-simple-rest";
import {API_URL} from "../../../constants";

const Context = React.createContext({name: 'Default'});

export const BotsTable: React.FC<TableProps<IBot>> = (props) => {
    const [api, contextHolder] = notification.useNotification();
    const [loadings, setLoadings] = useState<Map<string, boolean>>();


    const openNotification = (placement: NotificationPlacement, botName: string) => {
        api.success({
            message: `Restart bot ${botName}`,
            description: <Context.Consumer>{({name}) => `Bot was successfully restarted!`}</Context.Consumer>,
            placement,
        });
    };

    const openErrorNotification = (placement: NotificationPlacement, botName: string, message: string) => {
        api.error({
            message: `Restart bot ${botName}`,
            description: <Context.Consumer>{({name}) => `${message}`}</Context.Consumer>,
            placement,
        });
    };

    const contextValue = useMemo(() => ({name: 'Actions'}), []);

    const enterLoading = (index: string, botName: string, botId: string) => {
        setLoadings((prevLoadings) => {
            const newLoadings = new Map<string, boolean>();
            prevLoadings?.forEach((value, key) => newLoadings.set(key, value))

            newLoadings.set(index, true);
            return newLoadings;
        });

        dataProvider(API_URL + '/bots/restart').custom({
            url: API_URL + `/bots/restart?botId=${botId}`,
            method: "post",
        }).then(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = new Map<string, boolean>();
                prevLoadings?.forEach((value, key) => newLoadings.set(key, value))

                newLoadings.set(index, false);

                openNotification('topRight', botName);
                return newLoadings;
            });
        }).catch(reason => {
            setLoadings((prevLoadings) => {
                const newLoadings = new Map<string, boolean>();
                prevLoadings?.forEach((value, key) => newLoadings.set(key, value))

                newLoadings.set(index, false);

                openErrorNotification('topRight', botName, reason.message);
                return newLoadings;
            });
        })
    };

    return (
        <Context.Provider value={contextValue}>
            {contextHolder}
            <Table {...props} rowKey="id">
                <Table.Column
                    dataIndex="botName"
                    key="botName"
                    title="Bot name"
                    render={(value) => <TextField value={value}/>}
                />
                <Table.Column
                    dataIndex="exchange"
                    key="exchange"
                    title="Exchange"
                    render={(value) => <TextField value={value}/>}
                />
                <Table.Column
                    dataIndex="pair"
                    key="pair"
                    title="Trade pair"
                    render={(value) => <TextField value={value}/>}
                />
                <Table.Column
                    dataIndex="isCEX"
                    key="isCEX"
                    title="Exchange type"
                    render={(value) => <Tag
                        color={value ? "rgba(54,83,117,0.5)" : "rgb(35,43,98)"}>{value ? 'CEX' : 'DEX'}</Tag>}
                />
                <Table.Column
                    dataIndex="createdAt"
                    key="createdAt"
                    title="Created"
                    render={(value) => value ? <DateField format="L LT" value={value}/> : 'No date'}
                />
                <Table.Column
                    dataIndex="updatedAt"
                    key="updatedAt"
                    title="Updated"
                    render={(value) => value ? <DateField format="L LT" value={value}/> : 'No date'}
                />
                <Table.Column<IBot>
                    dataIndex="manage"
                    key="manage"
                    title="Manage"
                    render={(_, record) => (
                        <Space>
                            <Button
                                hidden={!record.process}
                                size="small"
                                icon={<SyncOutlined/>}
                                loading={loadings?.get(record.id)}
                                onClick={() => enterLoading(record.id, record.botName, record.id)}
                            >
                                Restart
                            </Button>
                        </Space>
                    )}
                />
                <Table.Column<IBot>
                    dataIndex="actions"
                    key="actions"
                    title="CRUD Actions"
                    render={(_, record) => (
                        <Space>
                            <ShowButton size="small" recordItemId={record.id}/>
                            <EditButton size="small" recordItemId={record.id}/>
                            <DeleteButton size="small" recordItemId={record.id}/>
                        </Space>
                    )}
                />
            </Table>
        </Context.Provider>
    )
}
