import {IResourceComponentsProps, useList, useShow} from "@pankod/refine-core";
import {BooleanField, Divider, Show, Table, TextField, Typography} from "@pankod/refine-antd";

import {IBot, IUser} from "interfaces";
import React from "react";
import {convertLocalDateToUTCIgnoringTimezone} from "../../utils";

const {Title, Text} = Typography;

type UserBotPermissionTableProps = { botId: string, botName: string, write: boolean, read: boolean };

export const UserShow: React.FC<IResourceComponentsProps> = () => {
    const {queryResult} = useShow<IUser>(
        {
            resource: 'users'
        }
    );

    const botsData = useList<IBot>(
        {
            resource: 'bots',
        }
    );

    const {data, isLoading} = queryResult;
    const record = data?.data;

    const readIds = record?.userRight.botPermissions.read ?? [];
    const writeIds = record?.userRight.botPermissions.write ?? [];
    const administrationIds = record?.userRight.botPermissions.administration ?? [];

    const availableBots = new Set<string>();
    readIds.forEach((id: string) => availableBots.add(id))
    writeIds.forEach((id: string) => availableBots.add(id))
    administrationIds.forEach((id: string) => availableBots.add(id))


    let availableBotsArray: UserBotPermissionTableProps[] = availableBots.size ? botsData.data?.data.map(botData => {
        return {
            botId: botData.id,
            botName: botData.botName,
            read: readIds.includes(botData.id),
            write: writeIds.includes(botData.id),
            administration: administrationIds.includes(botData.id),
        }
    }) ?? [] : [];

    return (
        <Show isLoading={isLoading}>
            <Title level={5}>Full name</Title>
            <Text>{record?.firstName + ' ' + record?.lastName}</Text>
            <Divider/>

            <Title level={5}>Email</Title>
            <Text>{record?.email}</Text>
            <Divider/>

            <Title level={5}>Created at</Title>
            <Text>{convertLocalDateToUTCIgnoringTimezone(new Date(Number(record?.createdAt))).toUTCString()} {convertLocalDateToUTCIgnoringTimezone(new Date(Number(record?.createdAt))).toUTCString()}</Text>
            <Divider/>

            <Title level={5}>Updated at</Title>
            <Text>{convertLocalDateToUTCIgnoringTimezone(new Date(Number(record?.updatedAt))).toUTCString()} {convertLocalDateToUTCIgnoringTimezone(new Date(Number(record?.updatedAt))).toUTCString()}</Text>
            <Divider/>

            <Title level={5}>Bot management permissions</Title>
            <Text>{`Creating bots: `} <BooleanField value={record?.userRight.managementPermissions.bots.create}/></Text>
            <Divider/>

            <Title level={5}>User management permissions</Title>
            <Text>{`View users: `} <BooleanField value={record?.userRight.managementPermissions.users.view}/></Text>
            <div></div>
            <Text>{`Manage users: `} <BooleanField value={record?.userRight.managementPermissions.users.manage}/></Text>
            <Divider/>

            <Title level={5}>Bot permissions
                <Table
                    dataSource={Array.of(...availableBotsArray ?? [])}
                    loading={botsData.isLoading}
                    pagination={false}
                    rowKey='botId'>
                    <Table.Column
                        dataIndex="botName"
                        key="botName"
                        title="Bot name"
                        render={(value) => <TextField value={value}/>}
                    />
                    <Table.Column
                        dataIndex="read"
                        key="read"
                        title="Read permission"
                        render={(value) => <BooleanField value={value}/>}
                    />
                    <Table.Column
                        dataIndex="write"
                        key="write"
                        title="Write permission"
                        render={(value) => <BooleanField value={value}/>}
                    />
                    <Table.Column
                        dataIndex="administration"
                        key="administration"
                        title="Administrator"
                        render={(value) => <BooleanField value={value}/>}
                    />
                </Table>
            </Title>
        </Show>
    );
};
