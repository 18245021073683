import {Checkbox, Form, FormProps, Input, Select} from "@pankod/refine-antd";
import React, {useState} from "react";

export const BotDEXCreate: React.FC<{ formProps: FormProps, exchanges: string[] | undefined, isCEX: boolean }> = (props) => {
    let [isRotationAccount, setIsRotationAccount] = useState(true);

    let onChange = (checkedValues: any) => {
        props.formProps.form?.setFieldValue("isRotationAccount", checkedValues.target.checked)
        setIsRotationAccount(checkedValues.target.checked)
    };


    return (
        <Form {...props.formProps} layout="vertical">
            <Form.Item label="Is CEX"
                       name="isCEX"
                       hidden={true}
                       initialValue={props.isCEX}>
                <Checkbox checked={props.isCEX}/>
            </Form.Item>
            <Form.Item label="Exchange"
                       name="exchange"
                       rules={[{required: true}]}>
                <Select showSearch
                        placeholder="Select an exchange"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={props.exchanges?.map(value => {
                            return {
                                value: value,
                                label: value
                            }
                        })}
                />
            </Form.Item>
            <Form.Item label="Pair"
                       name="pair"
                       rules={[{required: true, max: 255}]}>
                <Input placeholder={"COIN1/COIN2"}/>
            </Form.Item>
            <Form.Item label="Bot name"
                       name="botName"
                       rules={[{required: true, max: 255},]}>
                <Input placeholder={"R2D2"}/>
            </Form.Item>
            <Form.Item label="Welcome string"
                       name="welcomeString"
                       initialValue={"Hi. I'm a bot!"}
                       rules={[{required: true, max: 1000}]}>
                <Input/>
            </Form.Item>
            <Form.Item label="Telegram API token"
                       name="telegramApiToken"
                       rules={[{required: true}]}>
                <Input placeholder={"You should generate it in telegram bot"}/>
            </Form.Item>
            <Form.Item label="Is bot with rotation param?"
                       name="isRotationAccount"
                       rules={[{required: true}]}>
                <Checkbox defaultChecked={isRotationAccount} onChange={onChange}/>
            </Form.Item>
            <Form.Item label={isRotationAccount ? "Wallet seed phrase" : "Wallet private key"}
                       name="walletPrivateKey"
                       rules={[{required: true}]}>
                <Input
                    placeholder={isRotationAccount ?
                        "Enter a sid phrase (rotation) of your wallet" :
                        "Enter a private key (one wallet) of your wallet"}/>
            </Form.Item>
        </Form>
    );
};
