import {useList} from "@pankod/refine-core";

import {ITransaction} from "interfaces";
import React, {useEffect, useState} from "react";

import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip,} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {getFirstDayOfMonth, getLastDayOfMonth} from "../../../utils/date.utils";
import {DatePicker, Space} from "@pankod/refine-antd";
import type {RangePickerProps} from 'antd/es/date-picker';
import {convertLocalDateToUTCIgnoringTimezone} from "../../../utils";

const {RangePicker} = DatePicker;

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend
);

export const DexVolumeChart: React.FC<{ showId: string }> = (params) => {

    function getVolumeDataSet(transactions: ITransaction[] = [], labels: string[]): number[] {
        const filteredTransactions = transactions.filter((transaction) => transaction.status === "SUCCESS");

        const dayAndOrderPricesMap = filteredTransactions.reduce((map, transaction) => {
            const createdAt = convertLocalDateToUTCIgnoringTimezone(new Date(transaction.createdAt));

            const key = `${createdAt.getUTCDate()}/${createdAt.getUTCMonth() + 1}`;
            const orderPrice = transaction.orderPriceUSD;

            if (map.has(key)) {
                const values = map.get(key) ?? [];
                values.push(Math.ceil(orderPrice));
                map.set(key, values)
            } else {
                map.set(key, [Math.ceil(orderPrice)])
            }

            return map;

        }, new Map<string, number[]>());

        return labels.map(label => {
            let orderPrices = dayAndOrderPricesMap.get(label);

            if (dayAndOrderPricesMap.has(label) && orderPrices !== undefined) {
                orderPrices = orderPrices ?? [0];

                return orderPrices.reduce((sum, orderPrice) => sum + orderPrice, 0);
            } else {
                return 0;
            }
        });
    }

    function getLabelsData(firstDay: Date, lastDay: Date): string[] {
        let labelsData = new Array<Date>();

        for (let i = firstDay; i.getTime() <= lastDay.getTime(); i = convertLocalDateToUTCIgnoringTimezone(new Date(i.getFullYear(), i.getMonth(), i.getDate() + 1))) {
            labelsData.push(i);
        }

        return labelsData.map(value => `${value.getDate()}/${value.getMonth() + 1}`);
    }

    let [mainDate] = useState(convertLocalDateToUTCIgnoringTimezone(new Date()));
    let [firstDay, setFirstDay] = useState(getFirstDayOfMonth(mainDate));
    let [lastDay, setLastDay] = useState(getLastDayOfMonth(mainDate));
    let [labels, selLabels] = useState(new Array<string>());
    let [volumeDataset, selVolumeDataset] = useState(new Array<number>())

    const transactionListResponse = useList<ITransaction>({
        resource: 'transactions',
        config: {
            hasPagination: false,
            filters: [
                {
                    field: "id",
                    operator: "eq",
                    value: params.showId,
                },
                {
                    field: "createdAt",
                    operator: "between",
                    value: [firstDay.getTime(), lastDay.getTime()]
                }
            ]
        }
    });

    const onChange = (
        value: RangePickerProps['value'],
        dateString: [string, string] | string,
    ) => {
        if (!!dateString[0] && !!dateString[1]) {
            setFirstDay(convertLocalDateToUTCIgnoringTimezone(new Date(dateString[0])))
            setLastDay(convertLocalDateToUTCIgnoringTimezone(new Date(dateString[1])))
        } else {
            setFirstDay(getFirstDayOfMonth(mainDate))
            setLastDay(getLastDayOfMonth(mainDate))
        }
    };

    useEffect(() => {
        selLabels(getLabelsData(firstDay, lastDay));
        selVolumeDataset(getVolumeDataSet(transactionListResponse.data?.data, labels));
    }, [transactionListResponse.isLoading, firstDay, lastDay, transactionListResponse.data?.data])

    return (
        <>
            <Space>
                <RangePicker
                    size={'large'}
                    format="YYYY-MM-DD"
                    onChange={onChange}
                />
            </Space>
            <Bar
                options={{
                    responsive: true,
                    plugins: {
                        legend: {position: 'top' as const,},
                        title: {display: true, text: 'Volumes'},
                    },
                }}
                data={{
                    labels: labels,
                    datasets: [
                        {
                            label: 'volume in day (USD)',
                            backgroundColor: 'rgba(131,18,189,0.56)',
                            borderColor: 'rgba(70,0,75,0.56)',
                            borderWidth: 3,
                            borderRadius: 3,
                            borderSkipped: 'bottom',
                            data: volumeDataset,
                        }]
                }}/>
        </>
    );
};
