import {DateField, Table, Tag, TextField, useTable} from "@pankod/refine-antd";
import {IOrder} from "interfaces";
import React, {useEffect, useState} from "react";
import {useCustom} from "@pankod/refine-core";
import {API_URL} from "../../../constants";

export const BotOrdersList: React.FC<{ botId: string }> = (props) => {
    let [total, setTotal] = useState(0);

    const {tableProps} = useTable<IOrder>({
        resource: 'orders',
        initialFilter: [{
            field: 'botId',
            value: props.botId,
            operator: "eq"
        }],
        initialSorter: [{
            field: 'date',
            order: 'asc',
        }],
        hasPagination: true,
        initialCurrent: 1,
        initialPageSize: 100,
    });

    const totalResponse = useCustom<{ total: number }>({
        method: "get",
        url: API_URL + `/orders/count`,
        config: {
            filters: [{
                field: 'botId',
                value: props.botId,
                operator: "eq"
            }]
        },
    });


    useEffect(() => {
        setTotal(totalResponse.data?.data.total ?? 0)
    }, [totalResponse.isLoading])


    return (
        <div>
            <TextField value={total}></TextField>
            <Table {...tableProps} rowKey="id"
                   pagination={{total, position: ['topRight', 'bottomRight'], defaultPageSize: 100, current: 1}}>
                <Table.Column
                    dataIndex="id"
                    key="id"
                    title="ID"
                    render={(value) => <TextField value={value}/>}
                />
                <Table.Column
                    dataIndex="date"
                    key="date"
                    title="Date"
                    sorter={true}
                    defaultSortOrder={'ascend'}
                    render={(value) => <DateField format="L LT" value={value}/>}
                />
                <Table.Column
                    dataIndex="type"
                    key="type"
                    title="Type"
                    render={(value) => <Tag
                        color={value === 'buy' ? "rgba(68,166,46,0.75)" : "#a84141"}>{value?.toUpperCase() ?? ''}</Tag>}
                />
                <Table.Column
                    dataIndex="purpose"
                    key="purpose"
                    title="Purpose"
                    render={(value) => <TextField value={value}/>}
                />
                <Table.Column
                    dataIndex="pair"
                    key="pair"
                    title="Trade pair"
                    render={(value) => <TextField value={value}/>}
                />
                <Table.Column
                    dataIndex="price"
                    key="price"
                    title="Price"
                    render={(value) => <TextField value={value}/>}
                />
                <Table.Column<IOrder>
                    dataIndex="coin1Amount"
                    key="coin1Amount"
                    title="Coin 1 amount"
                    render={(_, record) => (<TextField value={`${record.coin1Amount} (${record.coin1})`}/>)}
                />
                <Table.Column<IOrder>
                    dataIndex="coin2Amount"
                    key="coin2Amount"
                    title="Coin 2 amount"
                    render={(_, record) => (<TextField value={`${record.coin2Amount} (${record.coin2})`}/>)}
                />
            </Table>
        </div>
    );
};
