import {LoginFormTypes,} from "@pankod/refine-core";
import dataProvider, {axiosInstance} from "@pankod/refine-simple-rest";
import {convertLocalDateToUTCIgnoringTimezone} from "./utils";

export const authProvider = {
    login: ({email, password}: LoginFormTypes) => {
        // Suppose we actually send a request to the back end here.
        const apiUrl: string = String(process.env.REACT_APP_MMGRAPH_BACKEND);

        return dataProvider(apiUrl).custom({
            url: `${apiUrl}/auth/login`,
            method: "post",
            payload: {
                username: email,
                password: password,
            }
        }).then(requestData => {
            const {access_token, refresh_token, user} = requestData.data;

            axiosInstance.defaults.headers.common = {
                Authorization: `Bearer ${access_token}`,
            };

            if (access_token && refresh_token && user) {
                localStorage.setItem("auth", JSON.stringify(access_token));
                localStorage.setItem("refresh", JSON.stringify(refresh_token));
                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem('refreshAt', JSON.stringify(convertLocalDateToUTCIgnoringTimezone(new Date()).getTime()));

                return Promise.resolve();
            }

            return Promise.reject();
        }).catch(e => {
            return Promise.reject();
        })
    },
    logout: () => {
        localStorage.removeItem("auth");
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem("auth") ? Promise.resolve() : Promise.reject();
    },
    checkError: (error: { statusCode: number }) => {
        if (error.statusCode === 401) {

            const item = localStorage.getItem('refresh') ?? '';

            localStorage.setItem("auth", item);

            const apiUrl: string = String(process.env.REACT_APP_MMGRAPH_BACKEND);

            return dataProvider(apiUrl).custom({
                url: `${apiUrl}/auth/refresh`,
                method: "post",
            }).then(requestData => {
                const {access_token, refresh_token, user} = requestData.data;

                axiosInstance.defaults.headers.common = {
                    Authorization: `Bearer ${access_token}`,
                };

                if (access_token && refresh_token && user) {
                    localStorage.setItem("auth", JSON.stringify(access_token));
                    localStorage.setItem("refresh", JSON.stringify(refresh_token));
                    localStorage.setItem("user", JSON.stringify(user));
                    localStorage.setItem('refreshAt', JSON.stringify(convertLocalDateToUTCIgnoringTimezone(new Date()).getTime()));

                    return Promise.resolve();
                }

                localStorage.removeItem("auth");
                localStorage.removeItem("refresh");
                localStorage.removeItem("user");
                localStorage.removeItem("refreshAt");


                return Promise.reject();
            }).catch(e => {
                localStorage.removeItem("auth");
                localStorage.removeItem("refresh");
                localStorage.removeItem("user");
                localStorage.removeItem("refreshAt");

                return Promise.reject();
            })
        }
        return Promise.resolve();
    },
    getPermissions: () => {
        const auth = localStorage.getItem("auth");
        if (auth) {
            const parsedUser = JSON.parse(auth);
            return Promise.resolve(parsedUser.roles);
        }
        return Promise.reject();
    },
    getUserIdentity: () => {
        const user = localStorage.getItem("user");
        if (user) {
            const parsedUser = JSON.parse(user);
            return Promise.resolve(parsedUser.firstName);
        }
        return Promise.reject();
    }
};
