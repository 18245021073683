import {IResourceComponentsProps} from "@pankod/refine-core";
import {List, Tabs, useTable} from "@pankod/refine-antd";
import {IBot} from "interfaces";
import React, {useEffect, useState} from "react";
import {BotsTable} from "./list-components/bots-table";
import dataProvider from "@pankod/refine-simple-rest";
import {API_URL} from "../../constants";

export const BotList: React.FC<IResourceComponentsProps> = () => {
    const {tableProps} = useTable<IBot>({
        resource: 'bots',
        hasPagination: false,
    });

    function getList() {
        return dataProvider(API_URL).getList<IBot>({
            resource: 'bots',
            hasPagination: false,
        });
    }

    let [data, setData] = useState(new Array<IBot>())

    const onChangeTab = (key: string) => {
        getList().then(value => {
            setData(Array.of(...value.data));
        })
    };

    useEffect(() => {
        setData(Array.of(...tableProps?.dataSource ?? new Array<IBot>()));
    }, [tableProps.loading])

    let dataSource = data?.filter(bot => bot.status === 1);
    let dataSource1 = data?.filter(bot => !bot.status || bot.status === 0);

    return (
        <List>
            <Tabs
                onChange={onChangeTab}
                defaultActiveKey="1"
                items={[
                    {
                        key: 'running',
                        label: `Running bots`,
                        children: (<BotsTable {...tableProps}
                                              dataSource={dataSource}></BotsTable>),
                    },
                    {
                        key: 'notRunning',
                        label: `Not running bots`,
                        children: (<BotsTable{...tableProps}
                                             dataSource={dataSource1}></BotsTable>),
                    },
                ]}/>
        </List>
    );
};
