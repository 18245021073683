import {IResourceComponentsProps, useCustom} from "@pankod/refine-core";
import {Checkbox, Edit, Form, Input, Select, useForm} from "@pankod/refine-antd";
import React, {useEffect, useState} from "react";
import {CreateTradeBotCEXDto, CreateTradeBotDEXDto} from "../../interfaces";
import {API_URL} from "../../constants";

export const BotEdit: React.FC<IResourceComponentsProps> = () => {

    const {formProps, saveButtonProps, formLoading} = useForm<CreateTradeBotCEXDto & CreateTradeBotDEXDto>({
        resource: 'bots',
    });

    let custom = useCustom<string[]>({
        url: `${API_URL}/bots/exchanges`,
        method: "get"
    });

    let [isCEX, setIsCEX] = useState(formProps.form?.getFieldValue('isCEX'));
    let [isRotationAccount, setIsRotationAccount] = useState(formProps.form?.getFieldValue('isRotationAccount'));


    useEffect(() => {
        setIsCEX(formProps.form?.getFieldValue('isCEX'));
        setIsRotationAccount(formProps.form?.getFieldValue('isRotationAccount'));
    }, [formLoading])

    let onChange = (checkedValues: any) => {
        formProps.form?.setFieldValue("isRotationAccount", checkedValues.target.checked)
        setIsRotationAccount(checkedValues.target.checked)
    };


    let dexRotation = !isCEX && isRotationAccount;
    let dexSimple = !isCEX && !isRotationAccount;
    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item label="Exchange"
                           name="exchange"
                           rules={[{required: true}]}>
                    <Select showSearch
                            placeholder="Select an exchange"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={custom.data?.data.map(value => {
                                return {
                                    value: value,
                                    label: value
                                }
                            })}
                    />
                </Form.Item>
                <Form.Item label="Pair"
                           name="pair"
                           rules={[{required: true, max: 255}]}>
                    <Input placeholder={"COIN1/COIN2"}/>
                </Form.Item>
                <Form.Item label="Bot name"
                           name="botName"
                           rules={[{required: true, max: 255},]}>
                    <Input placeholder={"R2D2"}/>
                </Form.Item>
                <Form.Item label="Welcome string"
                           name="welcomeString"
                           initialValue={"Hi. I'm a bot!"}
                           rules={[{required: true, max: 1000}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Telegram API token"
                           name="telegramApiToken"
                           rules={[{required: true}]}>
                    <Input placeholder={"You should generate it in telegram bot"}/>
                </Form.Item>
                <Form.Item label="API key"
                           name="apiKey"
                           rules={!isCEX ? [] : [{required: true}]}
                           hidden={!isCEX}>
                    <Input placeholder={"Enter an exchange API key"}/>
                </Form.Item>
                <Form.Item label="API secret"
                           name="apiSecret"
                           rules={!isCEX ? [] : [{required: true}]}
                           hidden={!isCEX}>
                    <Input placeholder={"Enter an exchange API secret"}/>
                </Form.Item>
                <Form.Item label="Is bot with rotation param?"
                           name="isRotationAccount"
                           hidden={isCEX}
                           rules={isCEX ? [] : [{required: true}]}>
                    <Checkbox defaultChecked={isRotationAccount} onChange={onChange}/>
                </Form.Item>
                <Form.Item label="Wallet seed phrase"
                           name="walletPrivateKey"
                           rules={dexRotation ? [{required: true}] : []}
                           hidden={!dexRotation}>
                    <Input placeholder={"Enter a seed phrase of your wallet"}/>
                </Form.Item>
                <Form.Item label="Wallet private key"
                           name="walletPrivateKey"
                           rules={dexSimple ? [{required: true}] : []}
                           hidden={!dexSimple}>
                    <Input placeholder={"Enter a private key of your wallet"}/>
                </Form.Item>
            </Form>
        </Edit>
    );
};
