import {convertLocalDateToUTCIgnoringTimezone} from "../utils";

export function getFirstDayOfMonth(date: Date = new Date()): Date {
    date = convertLocalDateToUTCIgnoringTimezone(date);
    return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getLastDayOfMonth(date: Date = new Date()): Date {
    date = convertLocalDateToUTCIgnoringTimezone(date);
    return new Date(date.getFullYear(), date.getMonth() + 1, 1);
}
