import {IResourceComponentsProps, useCustom} from "@pankod/refine-core";
import {Create, Tabs, useForm} from "@pankod/refine-antd";
import React, {useState} from "react";
import {BotCEXCreate} from "./create-components/create-cex";
import {BotDEXCreate} from "./create-components/create-dex";
import {CreateTradeBotCEXDto, CreateTradeBotDEXDto} from "../../interfaces";
import {API_URL} from "../../constants";

export const BotCreate: React.FC<IResourceComponentsProps> = () => {

    const cexFormProps = useForm<CreateTradeBotCEXDto>({
        resource: 'bots',
    });

    const dexFormProps = useForm<CreateTradeBotDEXDto>({
        resource: 'bots',
    });

    let custom = useCustom<string[]>({
        url: `${API_URL}/bots/exchanges`,
        method: "get"
    });

    let [saveButtonProps, setSaveButtonProps] = useState(cexFormProps.saveButtonProps);
    let [activeKey, setActiveKey] = useState("createCEX");
    let [isCEX, setIsCEX] = useState(true);

    const onChange = (key: string) => {

        setActiveKey(key)

        if (key === 'createCEX') {
            setIsCEX(true);
            setSaveButtonProps(cexFormProps.saveButtonProps);
        }

        if (key === 'createDEX') {
            setIsCEX(false);
            setSaveButtonProps(dexFormProps.saveButtonProps);
        }
    };

    const items = [
        {
            label: `Create CEX bot`,
            key: 'createCEX',
            children: (
                <BotCEXCreate formProps={cexFormProps.formProps} exchanges={custom.data?.data}
                              isCEX={isCEX}></BotCEXCreate>),
        },
        {
            label: `Create DEX bot`,
            key: 'createDEX',
            children: (
                <BotDEXCreate formProps={dexFormProps.formProps} exchanges={custom.data?.data}
                              isCEX={isCEX}></BotDEXCreate>),
        },
    ]

    return (
        <Create saveButtonProps={saveButtonProps}>

            <Tabs defaultActiveKey="createCEX"
                  activeKey={activeKey}
                  centered
                  items={items}
                  onChange={onChange}
            />
        </Create>
    );
};
