import {IResourceComponentsProps} from "@pankod/refine-core";
import {
    DateField,
    DeleteButton,
    EditButton,
    EmailField,
    List,
    ShowButton,
    Space,
    Table,
    TextField,
    useTable
} from "@pankod/refine-antd";
import {IUser} from "interfaces";
import {convertLocalDateToUTCIgnoringTimezone} from "../../utils";

export const UserList: React.FC<IResourceComponentsProps> = () => {
    const {tableProps} = useTable<IUser>({
        resource: 'users'
    });
    return (
        <List>
            <Table {...tableProps} rowKey="id">
                <Table.Column
                    dataIndex="email"
                    key="email"
                    title="Email"
                    render={(value) => <EmailField value={value}/>}
                />
                <Table.Column
                    dataIndex="firstName"
                    key="firstName"
                    title="First name"
                    render={(value) => <TextField value={value}/>}
                />
                <Table.Column
                    dataIndex="lastName"
                    key="lastName"
                    title="Last name"
                    render={(value) => <TextField value={value}/>}
                />
                <Table.Column
                    dataIndex="createdAt"
                    key="createdAt"
                    title="Created at"
                    render={(value) => <DateField format="L LT"
                                                  value={convertLocalDateToUTCIgnoringTimezone(new Date(value))}/>}
                />
                <Table.Column
                    dataIndex="updatedAt"
                    key="updatedAt"
                    title="Updated at"
                    render={(value) => <DateField format="L LT"
                                                  value={convertLocalDateToUTCIgnoringTimezone(new Date(value))}/>}
                />
                <Table.Column<IUser>
                    title="Actions"
                    dataIndex="actions"
                    key="actions"
                    render={(_, record) => (
                        <Space>
                            <ShowButton size="small" recordItemId={record.id}/>
                            <EditButton size="small" recordItemId={record.id}/>
                            <DeleteButton size="small" recordItemId={record.id}/>
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
